import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import request from "api/request";
import LoginBackground from "assets/images/login-background.svg";
import { appRoutes } from "assets/static/appRoutes";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import Text from "jsx/Common/Typography/Text";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "validations/Login/loginSchema";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "5px",
  },
  logo: { width: "400px", height: "40px", marginBottom: "30px" },
  loginLeftside: {
    backgroundColor: "rgba(28, 69, 136, 0.03)",
    padding: "35px",
    boxShadow: "0px 8px 12px #00000029",
  },
  loginRightside: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: { width: 50, height: 50 },
  colors: { color: "#666666" },
  signupColors: { color: theme.palette.primary.main, textDecoration: "none" },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const role = localStorage.getItem("role");
  const { companiesRedirect, homeRedirect } = appRoutes;

  const { useAppMutation } = useReactQuery();
  const {
    mutate: mutateKey,
    data: keyToken,
    isSuccess,
    isLoading: keyLoading,
  } = useAppMutation((data) =>
    request({
      method: "post",
      url: "/apikey/token",
      data,
    })
  );
  const { mutate: mutateCompany, isLoading: isLoadingCompany } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/update/user/company",
        data,
      }),
    {
      onSuccess: (data) => {
        localStorage.setItem("company_name", data?.data?.company?.name ?? "");
        localStorage.setItem("company_id", data?.data?.company_id ?? "");
        localStorage.setItem(
          "permissions",
          JSON.stringify(data?.permissions ?? "")
        );
        localStorage.setItem(
          "company_create_at",
          data?.data?.company?.created_at
        );
        queryClient.invalidateQueries(["counts"]);
        window.location.replace(
          `${role === "admin" ? companiesRedirect : homeRedirect}`
        );
      },
    }
  );
  const { mutate: mutateLogin, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/login/users",
        data,
        headers: {
          Authorization: `Bearer ${keyToken?.token}`,
        },
      }),
    {
      onSuccess: (data) => {
        localStorage.setItem("token", data?.token);
        localStorage.setItem("role", data?.user?.roles[0]?.name);
        localStorage.setItem("role_id", data?.user?.roles[0]?.id);
        localStorage.setItem("company_name", data?.user?.company?.name);
        localStorage.setItem("company_id", data?.user?.company_id);
        localStorage.setItem("permissions", JSON.stringify(data?.permissions));

        localStorage.setItem(
          "companies",
          JSON.stringify(data?.user?.companies)
        );

        localStorage.setItem(
          "ccm_companies",
          JSON.stringify(data?.user?.ccm_companies)
        );

        localStorage.setItem(
          "company_create_at",
          data?.user?.company?.created_at
        );
        const secs = data?.expires_in;
        const output = new Date();
        output.setSeconds(secs);
        localStorage.setItem("tokenExpireTime", output);

        if (data?.user?.roles[0]?.name === "admin") {
          navigate(appRoutes.companiesRedirect);
        } else {
          navigate(appRoutes.chooseCompany);
        }
      },
      enabled: keyToken?.token,
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      mutateKey({
        api_key: process.env.REACT_APP_API_KEY,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      mutateLogin({
        email: formik?.values.email,
        password: formik?.values.password,
      });
    }
  }, [isSuccess]);

  return (
    <>
      <Box
        height="100vh"
        width="100%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2} maxWidth="1440px" padding="0px 40px">
          <Grid item mobile={12}>
            <Flex mb={1}>
              <Logo />
            </Flex>
          </Grid>
          <Grid item mobile={12}>
            <Grid container className={classes.root}>
              <Grid item mobile={1} />
              <Grid
                item
                mobile={10}
                sm={10}
                md={10}
                laptop={5}
                desktop={5}
                className={classes.loginLeftside}
              >
                <Grid item mobile={12} sx={{ marginBottom: "10px" }}>
                  <Text variant="h5">Sign in to your account</Text>
                </Grid>
                <Grid item mobile={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <InputField
                      label="Email"
                      name="email"
                      type="email"
                      formik={formik}
                      cols={12}
                    />

                    <InputField
                      label="Password"
                      name="password"
                      type="password"
                      formik={formik}
                      cols={12}
                    />
                    <Flex
                      flexDirection="column"
                      justifyContent="start"
                      alignItems="start"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginTop: "15px" }}
                        text={"Sign in"}
                        isLoading={isLoading || keyLoading || isLoadingCompany}
                      />
                      <Text variant="body2">
                        Help! I've{" "}
                        <Link
                          to={appRoutes.forgotPasswordRedirect}
                          style={{
                            marginTop: "10px",
                            color: "#1C4588",
                            fontSize: "14px",
                            textDecoration: "none",
                          }}
                        >
                          Forgot Password
                        </Link>
                      </Text>
                    </Flex>
                  </form>
                </Grid>
              </Grid>
              <Grid
                item
                laptop={5}
                desktop={5}
                sx={{
                  display: {
                    mobile: "none",
                    tablet: "none",
                    laptop: "flex",
                    desktop: "flex",
                  },
                }}
              >
                <Grid item mobile={12} className={classes.loginRightside}>
                  <Text
                    variant="h2"
                    align="center"
                    color="white"
                    fontWeight="400"
                    mb={0}
                  >
                    Stay up to date with
                  </Text>
                  <Text
                    variant="h1"
                    align="center"
                    color="white"
                    my={1}
                    fontSize={"42px"}
                  >
                    Discharge Health!
                  </Text>
                  <Text variant="body2" align="center" color="white">
                    Product updates happen every month.
                  </Text>
                </Grid>
              </Grid>
              <Grid item mobile={1} />
            </Grid>
          </Grid>
          <Grid item mobile={12}>
            <Flex>
              <Text
                variant="body2"
                center
                maxWidth={() => {
                  return {
                    mobile: "440px",
                    tablet: "600px",
                    laptop: "600px",
                    desktop: "780px",
                  };
                }}
              >
                Please note that this platform may only be accessed by
                authorized account. By logging in, you are accepting our{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#183B73",
                    textDecoration: "underLine",
                  }}
                  onClick={() => {
                    navigate(appRoutes.termsOfServiceRedirect);
                  }}
                >
                  Terms of Service
                </span>
                . To find out how we protect you and your information, view our{" "}
                <span
                  style={{
                    color: "#183B73",
                    cursor: "pointer",
                    textDecoration: "underLine",
                  }}
                  onClick={() => {
                    navigate(appRoutes.privacyPolicyRedirect);
                  }}
                >
                  Privacy Policy.
                </span>
              </Text>
            </Flex>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
