import { Box, Grid } from "@mui/material";
import {
  AddMedicationsModalTableColumns,
  AddMedicationsModalTableData,
} from "assets/static/ccmStaticData";
import Button from "jsx/Common/Button/Button";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import CustomTable from "jsx/Common/Table/CustomTable";

const AddMedicationsModal = ({ setOpen }) => {
  const customHeaderStyle = {
    color: "#1C4588",
    fontSize: "16px",
    fontWeight: 600,
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <InputField cols={3} label={"Medicine"} />
          <SelectField cols={3} label={"Type"} />
          <InputField cols={3} label={"Quality"} />
          <InputField cols={3} label={"Direction"} />
        </Grid>
        <Grid item>
          <Button text={"Add"} width="100%" />
        </Grid>
        <Box sx={{ my: 1 }}>
          <CustomTable
            thStyle={customHeaderStyle}
            data={AddMedicationsModalTableData}
            columns={AddMedicationsModalTableColumns}
          />
        </Box>
        <FormButtons onClickCancel={() => setOpen(false)} />
      </Box>
    </>
  );
};

export default AddMedicationsModal;
