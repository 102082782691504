import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import Text from "../Typography/Text";

const CustomImageUpload = ({
  onFilesChange,
  label = "",
  name = "",
  textColor = "lightGrey",
  padding = "",
  isShowIcon = true,
  variant = "h4",
  my = 2,
}) => {
  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];

    if (selectedFile) {
      onFilesChange(selectedFile);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false, // Only allow a single file to be dropped
    accept: "image/*", // Accept only image files
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? "drag-active" : ""}`}
    >
      <input name={name} {...getInputProps()} />

      <p
        style={{
          display: "flex",
          alignItems: "center",
          padding: "1px",
          color: "#5EC4B2",
          cursor: "pointer",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        {isShowIcon && <CloudUploadIcon sx={{ color: "#1C4588" }} />}
        <Text
          my={my}
          variant={variant}
          color={textColor}
          marginLeft="5px"
          padding={padding}
        >
          {label}
        </Text>
      </p>
    </div>
  );
};

export default CustomImageUpload;
