import * as yup from "yup";

const DiagnosisSchema = yup.object().shape({
  icd10: yup
    .array()
    .of(yup.number())
    .min(1, "Diagnosis are required")
    .required("Diagnosis are required"),
});
export default DiagnosisSchema;
