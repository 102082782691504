import { Box, Grid } from "@mui/material";
import {
  DescriptionsDataArray,
  Medication1Table,
  Medication1Tablecolumns,
  Medication2Table,
  Medication2Tablecolumns,
} from "assets/static/ccmStaticData";
import {
  designCarePlanApprovalFieldsOptions,
  genders,
} from "assets/static/options";
import { useFormik } from "formik";
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import CustomTable from "jsx/Common/Table/CustomTable";
import Text from "jsx/Common/Typography/Text";
import { useState } from "react";
import { DesignCarePlanAapprovalSchema } from "../Validations/DesignCarePlanApproval/designCarePlanApprovalSchema";
import AddMedicationsModal from "./DesignCarePlanApprovalModal/AddMedicationsModal";
import LabTestModal from "./DesignCarePlanApprovalModal/LabTestModal";
import DashboardTooltip from "jsx/Screens/Dashboard/Components/NewDashBoardScreen/TooltipComponent/DashboardTooltip";
import Icon from "jsx/Common/Icons/Icon";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const DesignCarePlanApproval = () => {
  const [openLabTestModal, setOpenLabTestModal] = useState(false);
  const [openMedicationModal, setOpenMedicationModal] = useState(false);

  const handleOpenLabTest = () => {
    setOpenLabTestModal(true);
  };
  const handleOpenMedication = () => {
    setOpenMedicationModal(true);
  };

  const formik = useFormik({
    initialValues: {
      patientName: "",
      dateOfDiagnosis: "",
      birthDate: "",
      genderc: "",
      contactNumber: "",
      insurance: "",
      insuranceNo: "",
      patientCurrentCondition: "",
      currentTreatmentTherapy: "",
      chronic1: "",
      chronic2: "",
      chronic3: "",
      subType1: "",
      subType2: "",
      subType3: "",
      carePlanGoals: "",
      medicalManagement: "",
      careCoordination: "",
      psychosocialSupport: "",
      educationAndLifestyleModifications: "",
      followUpAndMonitoring: "",
      carePlanEvaluation: "",
    },
    validationSchema: DesignCarePlanAapprovalSchema,
    onSubmit: async (values) => {
      formik.resetForm();
    },
  });

  return (
    <>
      <CustomModal
        setOpen={setOpenLabTestModal}
        open={openLabTestModal}
        title={"Add Lab Test"}
        maxWidth="tablet"
        fullWidth={true}
      >
        <LabTestModal setOpen={setOpenLabTestModal} />
      </CustomModal>

      <CustomModal
        setOpen={setOpenMedicationModal}
        open={openMedicationModal}
        title={"Add Medications"}
        maxWidth="tablet"
        fullWidth={true}
      >
        <AddMedicationsModal setOpen={setOpenMedicationModal} />
      </CustomModal>

      <Box>
        <Grid container spacing={2}>
          <InputField
            label={"Patient Name"}
            cols={3}
            name="patientName"
            formik={formik}
          />
          <InputField
            label={"DoB"}
            type="date"
            cols={3}
            name="birthDate"
            formik={formik}
          />
          <InputField
            options={genders}
            cols={3}
            name="genderc"
            formik={formik}
            label={"Gender"}
          />
          <InputField
            label={"Contact Number"}
            cols={3}
            name="contactNumber"
            formik={formik}
          />
          <InputField
            label={"Date of Diagnosis"}
            cols={3}
            type="date"
            name="dateOfDiagnosis"
            formik={formik}
          />
          <InputField
            label={"Insurance #"}
            cols={3}
            name="insuranceNo"
            formik={formik}
          />
          <InputField
            label={"Insurance"}
            cols={3}
            name="insurance"
            formik={formik}
          />

          <Grid item desktop={4} laptop={3} tablet={6} mobile={12}>
            <Box
              sx={{
                backgroundColor: "#F3F3F3",
                borderRadius: "10px",
                p: 2,
              }}
            >
              <Grid container>
                <InputField
                  cols={12}
                  multiline
                  rows={6}
                  label={"Patient Current Condition"}
                  name="patientCurrentCondition"
                  formik={formik}
                />

                <InputField
                  cols={12}
                  multiline
                  rows={6}
                  label={"Current Treatment/Therapy"}
                  name="currentTreatmentTherapy"
                  formik={formik}
                />
                <Box sx={{ display: "flex" }}>
                  <Text variant="body2" my={1}>
                    Lab Test
                  </Text>
                  <DashboardTooltip
                    title={"Add Medications"}
                    placement={"right"}
                  >
                    <Icon
                      icon={<ControlPointIcon />}
                      onClick={handleOpenLabTest}
                      color="#1C4588"
                      width="10px"
                      height="10px"
                      marginLeft="10px"
                    />
                  </DashboardTooltip>
                </Box>
                <Flex
                  direction="column"
                  justifyContent="start"
                  alignItems="start"
                >
                  <Box sx={{ width: "100%" }} className="designCareTable">
                    <CustomTable
                      data={Medication1Table}
                      columns={Medication1Tablecolumns}
                    />
                  </Box>
                </Flex>
                <Box sx={{ display: "flex" }}>
                  <Text variant="body2" my={1}>
                    Medication
                  </Text>
                  <DashboardTooltip
                    title={"Add Medications"}
                    placement={"right"}
                  >
                    <Icon
                      icon={<ControlPointIcon />}
                      onClick={handleOpenMedication}
                      color="#1C4588"
                      width="10px"
                      height="10px"
                      marginLeft="10px"
                    />
                  </DashboardTooltip>
                </Box>
                <Flex
                  direction="column"
                  justifyContent="start"
                  alignItems="start"
                >
                  <Box
                    sx={{ width: "100%" }}
                    className="designCareTable second"
                  >
                    <CustomTable
                      data={Medication2Table}
                      columns={Medication2Tablecolumns}
                    />
                  </Box>
                </Flex>
              </Grid>
            </Box>
            <Button
              sx={{ width: "100%", backgroundColor: "#d92b2a" }}
              text={"Approve"}
              onClick={formik.submitForm}
            />
          </Grid>

          <Grid item desktop={8} laptop={8} tablet={6} mobile={12}>
            <Box
              sx={{
                backgroundColor: "#F3F3F3",
                p: 2,
                borderRadius: "10px",
              }}
            >
              <Grid container spacing={2}>
                <SelectField
                  label={"Chronic 1"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="chronic1"
                  formik={formik}
                  required
                />
                <SelectField
                  label={"Chronic 2"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="chronic2"
                  formik={formik}
                  required
                />
                <SelectField
                  label={"Chronic 3"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="chronic3"
                  formik={formik}
                />
                <SelectField
                  label={"Subtype"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="subType1"
                  formik={formik}
                />
                <SelectField
                  label={"Subtype"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="subType2"
                  formik={formik}
                />
                <SelectField
                  label={"Subtype"}
                  cols={4}
                  options={designCarePlanApprovalFieldsOptions}
                  name="subType3"
                  formik={formik}
                />
              </Grid>
              <Grid container spacing={2}>
                {DescriptionsDataArray?.map(({ label, name }) => {
                  return (
                    <InputField
                      cols={6}
                      multiline
                      rows={3}
                      label={label}
                      name={name}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DesignCarePlanApproval;
