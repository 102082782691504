import { Box, Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import Button from "jsx/Common/Button/Button";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Loader from "jsx/Common/Loaders/Loader";
import CustomTable from "jsx/Common/Table/CustomTable";
import { useEffect, useState } from "react";
import { LabTestSchema } from "../../Validations/DesignCarePlan/LabTestSchema";

const LabTestModal = ({ setOpen, formik: parentFormik, name }) => {
  const customHeaderStyle = {
    color: "#1C4588",
    fontSize: "16px",
    fontWeight: 600,
  };
  const LabTestModalTableColumns = [
    { Header: "HCPC", accessor: "hcpc" },
    { Header: "Lab Test Name", accessor: "name" },
    { Header: "Value", accessor: "value" },
  ];
  const { useFetch } = useReactQuery();
  const [selectedLabTest, setSelectedLabTest] = useState([]);
  const [options, setOptions] = useState([]);

  const { data: labTestData, isLoading: labTestDataLoading } = useFetch(
    "get-labTest",
    () =>
      request({
        method: "get",
        url: "/getLabTest",
      })
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      value: "",
      id: "",
      hcpc: "",
    },
    validationSchema: LabTestSchema,
    onSubmit: async (values) => {
      const selectedOption = options.find(
        (option) => option.short_description === values.name
      );
      if (selectedOption) {
        const newValue = {
          ...values,
          id: selectedOption.id,
          hcpc: selectedOption.hcpc,
        };
        setSelectedLabTest((prev) => [...prev, newValue]);
      }
      formik.resetForm();
      const clearButton = document.querySelector(
        ".Select_Practic .MuiAutocomplete-clearIndicator"
      );
      clearButton && clearButton.click();
    },
  });

  useEffect(() => {
    setSelectedLabTest(parentFormik?.values?.labTest);
  }, [parentFormik?.values?.labTest]);

  const handleSave = () => {
    parentFormik.setFieldValue(name, selectedLabTest); // Update parent formik state
    handleCancel();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (labTestData?.data?.length) {
      const uniqueArray = labTestData?.data?.reduce((acc, current) => {
        const x = acc.find((item) => item.hcpc === current.hcpc);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setOptions(uniqueArray);
    }
  }, [labTestData?.data]);

  return (
    <>
      {labTestDataLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            spacing={1}
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <SelectField
              classes="Select_Practic"
              formik={formik}
              cols={5}
              label={"Lab Test Name"}
              name="name"
              options={options?.map(({ id, short_description, hcpc }) => ({
                label: `${hcpc} - ${short_description}`,
                value: short_description,
              }))}
            />
            <InputField formik={formik} cols={5} label={"Value"} name="value" />
            <Grid item desktop={2}>
              <Button text={"Add"} width="100%" onClick={formik.submitForm} />
            </Grid>
          </Grid>
          {!!selectedLabTest?.length && (
            <>
              <Box sx={{ my: 1 }}>
                <CustomTable
                  thStyle={customHeaderStyle}
                  data={selectedLabTest}
                  columns={LabTestModalTableColumns}
                />
              </Box>
              <FormButtons
                onClickCancel={handleCancel}
                onClickSave={handleSave}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default LabTestModal;
