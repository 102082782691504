import { Grid } from "@mui/material";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Text from "jsx/Common/Typography/Text";
import { useQueryClient } from "react-query";

const DeleteInsurance = ({
  setOnDelete,
  selectedInsurance,
  patientId,
  setSelectedInsurance,
}) => {
  const { showToast } = useToast();
  const { useAppMutation } = useReactQuery();
  const queryClient = useQueryClient();

  const { mutate: mutateInsurance, isLoading: insuranceLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: `/delete-insurance`,
          data,
        }),
      {
        onSuccess: (data) => {
          const message = data?.message ?? "Insurance deleted successfully!";
          showToast(message, {
            type: "success",
          });
          setSelectedInsurance({});
          queryClient.invalidateQueries(["get-insurance"]);
          handleCancel();
        },
      }
    );

  const handleCancel = () => {
    setOnDelete(false);
  };

  return (
    <Grid>
      <Flex>
        <Text variant="h3" color={"black"}>
          Are you sure you want to delete Insurance ?
        </Text>
      </Flex>
      <Flex>
        <FormButtons
          save="Delete"
          cancel="No"
          saveLoading={insuranceLoading}
          onClickSave={() =>
            mutateInsurance({
              patient_id: patientId,
              insuranceId: selectedInsurance?.insuranceId,
            })
          }
          onClickCancel={handleCancel}
        />
      </Flex>
    </Grid>
  );
};

export default DeleteInsurance;
