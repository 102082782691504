import { Grid } from "@mui/material";
import { RelationOptions } from "assets/static/ccmStaticData";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import { memo, useEffect, useState } from "react";

const GuardianForm = ({ formik }) => {
  const [contact, setContact] = useState("");

  useEffect(() => {
    formik.setFieldValue("caregiverContact", contact);
  }, [contact]);

  useEffect(() => {
    setContact(formik.values.caregiverContact);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <SelectField
          label="Relationship"
          cols={4}
          options={RelationOptions}
          name="caregiverRelationship"
          formik={formik}
          placeholder="select..."
          defaultValue={{
            label: formik.values.caregiverRelationship
              ? RelationOptions.find(
                  (relation) =>
                    relation.value === formik.values.caregiverRelationship
                )?.label
              : "",
            value: formik.values.caregiverRelationship
              ? RelationOptions.find(
                  (relation) =>
                    relation.value === formik.values.caregiverRelationship
                )?.value
              : "",
          }}
        />

        <>
          <InputField
            label="Full Name"
            cols={4}
            name="guardianFullName"
            formik={formik}
          />
          <InputField
            label="Contact No"
            isPhone
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            cols={4}
          />
        </>
      </Grid>
    </>
  );
};

export default memo(GuardianForm);
