import { Grid } from "@mui/material";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Text from "jsx/Common/Typography/Text";
import { useQueryClient } from "react-query";

const DeleteDocuments = ({
  setOnDelete,
  selectedDocument,
  patientId,
  setSelectedDocument,
}) => {
  const { showToast } = useToast();
  const { useAppMutation } = useReactQuery();
  const queryClient = useQueryClient();

  const { mutate: mutateDocument, isLoading: documentLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/delete-document-upload",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Document Deleted Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["get-documents"]);
        setSelectedDocument({});
        handleCancel();
      },
    }
  );
  const handleCancel = () => {
    setOnDelete(false);
  };

  return (
    <Grid>
      <Flex>
        <Text variant="h3" color={"black"}>
          Are you sure you want to delete Document ?
        </Text>
      </Flex>
      <Flex>
        <FormButtons
          save="Delete"
          cancel="No"
          saveLoading={documentLoading}
          onClickSave={() =>
            mutateDocument({
              patient_id: patientId,
              attachment_id: selectedDocument?.id,
            })
          }
          onClickCancel={handleCancel}
        />
      </Flex>
    </Grid>
  );
};

export default DeleteDocuments;
