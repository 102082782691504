import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import InputField from "../FormComponents/InputField";
import LineLoader from "../Loaders/LineLoader";

const useStyles = makeStyles(() => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "12px",
      height: "12px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "20px",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      backgroundColor: "rgb(223 221 221)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgb(198 197 197)",
    },
  },
}));

function GenericTable({
  columns: cols,
  data: rowsData = [],
  search,
  recordsPerPage,
  tableHeight = "auto",
  isLoading = false,
  totalDataLength,
  totalPages,
  pages,
  prevPage,
  setPage,
  setPrevPage,
  // pageDetails,
}) {
  const classes = useStyles();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (rowsData) {
      setIsTableLoading(false);
      setData(rowsData);
      setTimeout(() => {
        goToCurrentPage();
      }, 50);
    }
  }, [rowsData]);
  function goToCurrentPage() {
    gotoPage(currentPage);
  }

  const columns = useMemo(() => cols, [cols]);

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    rows,
    setGlobalFilter,
    page,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: prevPage ?? 0,
        pageSize: recordsPerPage || 10,
      },
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const maxHeight =
    (isSmallScreen ? "auto" : tableHeight) === "auto" ? "none" : tableHeight;
  const tableOverflowY =
    rows?.length > 0 && rows?.length * 50 > maxHeight ? "scroll" : "auto";

  const tablePagination = (newPage) => {
    if (data?.length < totalDataLength) {
      if (recordsPerPage * (newPage + 1) > data?.length) {
        setCurrentPage(newPage);
        setPage && setPage(pages + 1);
        setData([]);
      }
    }
    gotoPage(newPage);
  };
  return (
    <Box sx={{ marginY: 2 }}>
      {search && (
        <Grid container>
          <InputField
            Placeholder="Search"
            name="name"
            value={globalFilter || ""}
            type="text"
            cols={3}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Grid>
      )}
      <TableContainer
        className={classes.scrollbar}
        sx={{
          border: "1px solid rgb(223 221 221)",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;",
          marginY: 2,
          height: tableHeight,
          maxHeight: tableHeight,
          overflowY: tableOverflowY,
        }}
        component={Paper}
      >
        <Table {...getTableProps()} sx={{ border: "none" }}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="sticky-last-column "
                    sx={{
                      padding: "10px",
                      color: "#1C4588",
                      fontWeight: "bold",
                      border: "1px solid rgb(223 221 221)",
                      borderTop: "none !important",
                      "&:first-child": { borderLeft: "none !important" },
                      "&:last-child": { borderRight: "none !important" },
                      width: "auto", // Set width to adjust according to content
                      minWidth: 0, // Allow cell to shrink
                      whiteSpace: "nowrap", // Prevent line breaks
                      overflow: "hidden", // Hide overflowing content
                      textOverflow: "ellipsis", // Add ellipsis for truncated content
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box component="span">{column.render("Header")}</Box>
                      {column.isSorted ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell sx={{ padding: "0px!important" }} colSpan={999}>
                <LineLoader />
              </TableCell>
            </TableRow>
          ) : (
            rows?.length > 0 && (
              <TableBody {...getTableBodyProps()} id="table-body">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <TableCell
                          {...cell.getCellProps()}
                          className="sticky-last-column "
                          sx={{
                            padding: "10px",
                            border: "1px solid rgb(223 221 221)",
                            "&:first-child": { borderLeft: "none !important" },
                            "&:last-child": { borderRight: "none !important" },
                            width: "auto", // Set width to adjust according to content
                            minWidth: 0, // Allow cell to shrink
                            whiteSpace: "nowrap", // Prevent line breaks
                            overflow: "hidden", // Hide overflowing content
                            textOverflow: "ellipsis", // Add ellipsis for truncated content
                          }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            )
          )}
          {rows?.length <= 0 && (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{
                    border: "1px solid rgb(223 221 221)",
                    "&:first-child": { borderLeft: "none !important" },
                    "&:last-child": { borderRight: "none !important" },
                  }}
                >
                  No data available
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pageCount > 0 &&
        (!isTableLoading ? (
          <TablePagination
            rowsPerPageOptions={setPage === undefined ? [10, 20, 30] : []}
            count={totalDataLength > 0 ? totalDataLength : data?.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={(e, newPage) => tablePagination(newPage)}
            onRowsPerPageChange={(event) => {
              setPageSize(Number(event.target.value));
            }}
            sx={{
              border: "1px solid rgb(223 221 221)",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
            }}
          />
        ) : null)}
    </Box>
  );
}

export default GenericTable;
