import * as yup from "yup";

const DesignCarePlanSchema = yup.object().shape({
  chronic1: yup.string().required("Chronic 1 is required"),
  chronic2: yup.string().required("Chronic 2 is required"),
  patientCurrentCondition: yup
    .string()
    .required("Patient Current Condition is required"),
  currentTreatmentTherapy: yup
    .string()
    .required("Current Treatment Therapy is required"),
  labTest: yup.array().min(1, "Lab Test is required"),
  carePlanGoals: yup.string().required("Care Plan Goals is required"),
  medicalManagement: yup.string().required("Medical Management is required"),
  careCoordination: yup.string().required("Care Coordination is required"),
  psychosocialSupport: yup.string().required("Psychosocial Support is required"),
  educationAndLifestyleModifications: yup
    .string()
    .required("Education And Lifestyle Modifications is required"),
  carePlanEvaluation: yup.string().required("Care Plan Evaluation is required"),
  followUpAndMonitoring: yup
    .string()
    .required("Follow Up And Monitoring is required"),
  dateOfDiagnosis: yup.string().required("Date of Diagnosis is required"),
});
export { DesignCarePlanSchema };
