import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import Button from "jsx/Common/Button/Button";
import IcdsSearch from "jsx/Common/FormComponents/IcdsSearch";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import Text from "jsx/Common/Typography/Text";
import { memo, useState } from "react";
import AddDiagnosisForm from "../AddDiagnosisForm";
import TableActionButtons from "jsx/Common/Table/TableActionButtons";
import DiagnosisSchema from "jsx/Screens/Ccm/Validations/AddPatient/DiagnosisSchema";
import useReactQuery from "hooks/useReactQuery";
import request from "api/request";
import useToast from "hooks/useToast";
import { useQueryClient } from "react-query";
import Loader from "jsx/Common/Loaders/Loader";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import DeleteDiagnosis from "./DeleteDiagnosis";

const DiagnosisData = ({ patientId }) => {
  const [open, setOpen] = useState(false);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState({});
  const [onDelete, setOnDelete] = useState(false);

  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { data: diagnosis, isLoading: getDiagnosisLoading } = useFetch(
    ["get-diagnosis", patientId], //patientId is mandatry for edit case
    () =>
      request({
        method: "get",
        url: `/get-diagnosis?patient_id=${patientId}`,
      }),
    {
      enabled: !!patientId,
    }
  );

  const { mutate: mutateDiagnosis, isLoading: addDiagnosisLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: "/diagnosis-create",
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("Diagnosis Added Successfully", {
            type: "success",
          });
          const clearButton = document.querySelector(
            ".Select_Practic .MuiAutocomplete-clearIndicator"
          );
          clearButton && clearButton.click();
          queryClient.invalidateQueries(["get-diagnosis"]);
          queryClient.invalidateQueries(["icd-code"]);
          formik.resetForm();
        },
      }
    );

  const columns = [
    { Header: "Description", accessor: "icd_code_description" },
    { Header: "ICD-10", accessor: "icd_code" },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        return (
          <TableActionButtons
            onDelete={() => {
              setSelectedDiagnosis(row?.original);
              setOnDelete(true);
            }}
            showEdit={false}
          />
        );
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      icd10: [],
      patient_id: patientId,
    },
    validationSchema: DiagnosisSchema,
    onSubmit: async (values) => {
      mutateDiagnosis(values);
    },
  });

  return (
    <>
      {getDiagnosisLoading ? (
        <Loader />
      ) : (
        <Box sx={{ position: "relative", top: "40px" }}>
          <CustomModal
            maxWidth="tablet"
            title={"Add New Diagnosis"}
            setOpen={setOpen}
            open={open}
          >
            <AddDiagnosisForm setOpen={setOpen} />
          </CustomModal>
          <CustomModal
            setOpen={setOnDelete}
            open={onDelete}
            title={"Delete Diagnosis"}
            width="auto"
            maxWidth="tablet"
          >
            <DeleteDiagnosis
              patientId={patientId}
              setOnDelete={setOnDelete}
              selectedDiagnosis={selectedDiagnosis}
              setSelectedDiagnosis={setSelectedDiagnosis}
            />
          </CustomModal>

          <Box sx={{ position: "absolute", right: "0px", top: "22px" }}>
            <FormButtons
              save={"Add Diagnosis"}
              isCancel={false}
              onClickSave={formik.submitForm}
              saveLoading={addDiagnosisLoading}
            />
          </Box>
          <Box sx={{ position: "absolute", right: "0px", top: "-50px" }}>
            <Button text={"Add New Diagnosis"} onClick={() => setOpen(true)} />
          </Box>
          <Grid container>
            <Grid item mobile={8} tablet={8} laptop={6}>
              <Box sx={{ display: "flex" }}>
                <Text variant="body2" my={1}>
                  Diagnosis {true && <span style={{ color: "red" }}>*</span>}
                </Text>
              </Box>
              <IcdsSearch
                classes="Select_Practic"
                placeholder="Search..."
                formik={formik}
                selectedSearchData={diagnosis?.data}
                name="icd10"
                errorMessage="Diagnosis are required"
              />
            </Grid>
          </Grid>

          <GenericTable
            columns={columns}
            data={diagnosis?.data}
            isLoading={getDiagnosisLoading}
          />
        </Box>
      )}
    </>
  );
};
export default memo(DiagnosisData);
