import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Box, Grid, Typography } from "@mui/material";
import request from "api/request";
import {
  DescriptionsDataArray,
  Medication2Table,
  Medication2Tablecolumns,
} from "assets/static/ccmStaticData";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Icon from "jsx/Common/Icons/Icon";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Loader from "jsx/Common/Loaders/Loader";
import CustomModal from "jsx/Common/Modal/CustomModal";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import CustomTable from "jsx/Common/Table/CustomTable";
import Text from "jsx/Common/Typography/Text";
import DashboardTooltip from "jsx/Screens/Dashboard/Components/NewDashBoardScreen/TooltipComponent/DashboardTooltip";
import { useEffect, useState } from "react";
import AddMedicationsModal from "../DesignCarePlanApproval/DesignCarePlanApprovalModal/AddMedicationsModal";
import LabTestModal from "../DesignCarePlanApproval/DesignCarePlanApprovalModal/LabTestModal";
import { DesignCarePlanSchema } from "../Validations/DesignCarePlan/DesignCarePlanSchema";
import useToast from "hooks/useToast";
import { useQueryClient } from "react-query";

const DesignCarePlan = ({ selectedDesignCarePlan, setOpen }) => {
  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [openLabTestModal, setOpenLabTestModal] = useState(false);
  const [openMedicationModal, setOpenMedicationModal] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const { data: chronicData, isLoading: chronicDataLoading } = useFetch(
    "get-chronic",
    () =>
      request({
        method: "get",
        url: "/getChronic",
      })
  );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/care-plan-store",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Design Care Plan Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["ccm-design-care-plan"]);
        queryClient.invalidateQueries(["ccm-counts"]);
        handleCancel();
        formik.resetForm();
      },
    }
  );

  const handleOpenLabTest = () => {
    setOpenLabTestModal(true);
  };
  const handleOpenMedication = () => {
    setOpenMedicationModal(true);
  };
  const LabTestModalTableColumns = [
    { Header: "Lab Test Name", accessor: "name" },
    { Header: "Value", accessor: "value" },
  ];

  const formik = useFormik({
    initialValues: {
      patient_id: selectedDesignCarePlan?.id,
      patientCurrentCondition: "",
      currentTreatmentTherapy: "",
      labTest: [],
      chronic1: "",
      chronic2: "",
      chronic3: "",
      carePlanGoals: "",
      medicalManagement: "",
      careCoordination: "",
      psychosocialSupport: "",
      educationAndLifestyleModifications: "",
      followUpAndMonitoring: "",
      carePlanEvaluation: "",
      dateOfDiagnosis: "",
    },
    validationSchema: DesignCarePlanSchema,
    onSubmit: async (values) => {
      let labTest = values?.labTest?.map((item) => item.id) || [];
      const data = { ...values, labTest };
      mutate(data);
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {chronicDataLoading ? (
        <Loader />
      ) : (
        <>
          <PatientDetail patientRecord={selectedDesignCarePlan} isCcm />
          <CustomModal
            setOpen={setOpenLabTestModal}
            open={openLabTestModal}
            title={"Add Lab Test"}
            maxWidth="tablet"
            fullWidth={true}
          >
            <LabTestModal
              setOpen={setOpenLabTestModal}
              formik={formik}
              name={"labTest"}
            />
          </CustomModal>
          <CustomModal
            setOpen={setOpenMedicationModal}
            open={openMedicationModal}
            title={"Add Medications"}
            maxWidth="tablet"
            fullWidth={true}
          >
            <AddMedicationsModal setOpen={setOpenMedicationModal} />
          </CustomModal>
          <Box>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item desktop={4} laptop={3} tablet={6} mobile={12}>
                <Box
                  sx={{
                    backgroundColor: "#F3F3F3",
                    borderRadius: "10px",
                    p: 2,
                  }}
                >
                  <Grid container>
                    <InputField
                      cols={12}
                      multiline
                      rows={4}
                      label={"Patient Current Condition"}
                      name="patientCurrentCondition"
                      formik={formik}
                      required="required"
                    />
                    <InputField
                      cols={12}
                      multiline
                      rows={4}
                      label={"Current Treatment/Therapy"}
                      name="currentTreatmentTherapy"
                      formik={formik}
                      required="required"
                    />
                    <Box sx={{ display: "flex" }}>
                      <Text variant="body2" my={1}>
                        Lab Test
                        {true && <span style={{ color: "red" }}>*</span>}
                      </Text>
                      <DashboardTooltip
                        title={"Add Lab Test"}
                        placement={"right"}
                      >
                        <Icon
                          icon={<ControlPointIcon />}
                          onClick={handleOpenLabTest}
                          color="#1C4588"
                          width="10px"
                          height="10px"
                          marginLeft="10px"
                        />
                      </DashboardTooltip>
                    </Box>
                    <Flex
                      direction="column"
                      justifyContent="start"
                      alignItems="start"
                    >
                      {!!formik.errors.labTest && (
                        <Typography variant="caption" color="error">
                          {formik.errors.labTest}
                        </Typography>
                      )}
                    </Flex>
                    <Flex
                      direction="column"
                      justifyContent="start"
                      alignItems="start"
                    >
                      {!!formik?.values?.labTest?.length && (
                        <Box sx={{ width: "100%" }} className="designCareTable">
                          <CustomTable
                            data={formik?.values?.labTest}
                            columns={LabTestModalTableColumns}
                          />
                        </Box>
                      )}
                    </Flex>
                    <Box sx={{ display: "flex" }}>
                      <Text variant="body2" my={1}>
                        Medication
                      </Text>
                      <DashboardTooltip
                        title={"Add Medications"}
                        placement={"right"}
                      >
                        <Icon
                          icon={<ControlPointIcon />}
                          onClick={handleOpenMedication}
                          color="#1C4588"
                          width="10px"
                          height="10px"
                          marginLeft="10px"
                        />
                      </DashboardTooltip>
                    </Box>
                    <Flex
                      direction="column"
                      justifyContent="start"
                      alignItems="start"
                    >
                      <Box
                        sx={{ width: "100%" }}
                        className="designCareTable second"
                      >
                        <CustomTable
                          data={Medication2Table}
                          columns={Medication2Tablecolumns}
                        />
                      </Box>
                    </Flex>
                  </Grid>
                </Box>
                <Button
                  isLoading={isLoading}
                  sx={{ width: "100%", backgroundColor: "#4fa571" }}
                  text={"Save and Send for Approval"}
                  onClick={formik.submitForm}
                />
              </Grid>

              <Grid item desktop={8} laptop={8} tablet={6} mobile={12}>
                <Box
                  sx={{
                    backgroundColor: "#F3F3F3",
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <SelectField
                      label={"Chronic 1"}
                      options={chronicData?.data?.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      cols={4}
                      name="chronic1"
                      required
                      formik={formik}
                    />
                    <SelectField
                      label={"Chronic 2"}
                      options={chronicData?.data?.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      cols={4}
                      required
                      name="chronic2"
                      formik={formik}
                    />
                    <SelectField
                      label={"Chronic 3"}
                      options={chronicData?.data?.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      cols={4}
                      name="chronic3"
                      formik={formik}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    {DescriptionsDataArray?.map(({ label, name }) => {
                      return (
                        <InputField
                          cols={6}
                          multiline
                          rows={3}
                          label={label}
                          name={name}
                          formik={formik}
                          required="required"
                        />
                      );
                    })}
                    <InputField
                      label={"Date of Diagnosis"}
                      cols={6}
                      type="date"
                      name="dateOfDiagnosis"
                      formik={formik}
                      required="required"
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
export default DesignCarePlan;
