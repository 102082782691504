import { Box, Grid } from "@mui/material";
import Button from "jsx/Common/Button/Button";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import { memo, useEffect, useState } from "react";
import InsuranceForm from "../../../Components/InsuranceData/InsuranceForm";
import SelectField from "jsx/Common/FormComponents/SelectField";
import InputField from "jsx/Common/FormComponents/InputField";
import { InsuranceTypeOptions } from "assets/static/ccmStaticData";
import TableActionButtons from "jsx/Common/Table/TableActionButtons";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import Loader from "jsx/Common/Loaders/Loader";
import { useFormik } from "formik";
import { InsuranceSchema } from "jsx/Screens/Ccm/Validations/AddPatient/InsuranceSchema";
import useToast from "hooks/useToast";
import { useQueryClient } from "react-query";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import DeleteInsurance from "../../../Components/InsuranceData/DeleteInsurance";

const Insurance = ({ patientId }) => {
  const [open, setOpen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const [insurancePptions, setInsuranceOptions] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      insuranceId: "",
      insuranceType: "",
      policyNo: "",
      patient_id: patientId,
    },
    validationSchema: InsuranceSchema,
    onSubmit: async (values) => {
      mutateInsurance(values);
    },
  });
  const { data: insurancesData, isLoading: insurancesDataLoading } = useFetch(
    "insurances",
    () =>
      request({
        method: "get",
        url: "/insurances",
      })
  );
  const { data: patientInsuranceData, isLoading: patientInsuranceDataLoading } =
    useFetch(
      ["get-insurance", patientId], //patientId is mandatry for edit case
      () =>
        request({
          method: "get",
          url: `/get-insurance?patient_id=${patientId}`,
        }),
      {
        enabled: !!patientId,
      }
    );
  const { mutate: mutateInsurance, isLoading: addInsuranceLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: "/insurance-create",
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("Insurance Added Successfully", {
            type: "success",
          });

          queryClient.invalidateQueries(["get-insurance"]);
          formik.resetForm();
          const [clearInsuranceButton, clearTypeButton] = [
            document.querySelector(
              ".Select_Insurance .MuiAutocomplete-clearIndicator"
            ),
            document.querySelector(
              ".Select_Type .MuiAutocomplete-clearIndicator"
            ),
          ];

          clearTypeButton && clearTypeButton.click();
          clearInsuranceButton && clearInsuranceButton.click();
        },
      }
    );
  useEffect(() => {
    if (insurancesData?.insurances?.length && patientInsuranceData?.data) {
      const filteredNewOptions = insurancesData?.insurances?.reduce(
        (acc, item) => {
          const isAlreadySelected = patientInsuranceData?.data?.some(
            (selected) => selected.insuranceId === item.id
          );

          if (!isAlreadySelected) {
            acc.push(item);
          }

          return acc;
        },
        []
      );

      setInsuranceOptions(filteredNewOptions);
    }
  }, [insurancesData?.insurances, patientInsuranceData?.data]);

  const columns = [
    { Header: "Insurance", accessor: "insurance" },
    { Header: "Type", accessor: "type" },
    { Header: "Policy #", accessor: "policyNo" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <TableActionButtons
            onDelete={() => {
              setSelectedInsurance(row?.original);
              setOnDelete(true);
            }}
            showEdit={false}
          />
        );
      },
    },
  ];

  return (
    <>
      {insurancesDataLoading ? (
        <Loader />
      ) : (
        <Box sx={{ position: "relative", top: "40px" }}>
          <CustomModal
            maxWidth="tablet"
            title={"Add Insurance Company"}
            setOpen={setOpen}
            open={open}
          >
            <InsuranceForm setOpen={setOpen} />
          </CustomModal>

          <CustomModal
            setOpen={setOnDelete}
            open={onDelete}
            title={"Delete Insurance"}
            width="auto"
            maxWidth="tablet"
          >
            <DeleteInsurance
              patientId={patientId}
              setOnDelete={setOnDelete}
              selectedInsurance={selectedInsurance}
              setSelectedInsurance={setSelectedInsurance}
            />
          </CustomModal>
          <Box sx={{ position: "absolute", right: "0px", top: "-50px" }}>
            <Button
              text={"Add Insurance Company"}
              onClick={() => {
                setOpen(true);
              }}
            />
          </Box>
          <Grid container columnSpacing={1}>
            <SelectField
              classes="Select_Insurance"
              options={insurancePptions?.map(({ id, insuranceName }) => {
                return { label: insuranceName, value: id };
              })}
              label="Insurance"
              name={"insuranceId"}
              required
              formik={formik}
              cols={3}
            />
            <SelectField
              classes="Select_Type"
              label="Type"
              cols={3}
              name={"insuranceType"}
              required
              formik={formik}
              options={InsuranceTypeOptions}
            />
            <InputField
              label="Policy #"
              cols={3}
              name="policyNo"
              required
              formik={formik}
            />
            <Box sx={{ position: "absolute", right: "-10px", top: "22px" }}>
              <FormButtons
                save={"Add Insurance"}
                isCancel={false}
                onClickSave={formik.submitForm}
                saveLoading={addInsuranceLoading}
              />
            </Box>
          </Grid>

          <GenericTable
            columns={columns}
            data={patientInsuranceData?.data}
            isLoading={patientInsuranceDataLoading}
          />
        </Box>
      )}
    </>
  );
};

export default memo(Insurance);
