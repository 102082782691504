import { Box } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import Button from "jsx/Common/Button/Button";
import CustomImageUpload from "jsx/Common/FormComponents/CustomImageUpload";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Loader from "jsx/Common/Loaders/Loader";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import TableActionButtons from "jsx/Common/Table/TableActionButtons";
import DocumentsSchema from "jsx/Screens/Ccm/Validations/AddPatient/DocumentsSchema";
import { memo, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import DeleteDocuments from "./DeleteDocuments";

const DocumentsData = ({ patientId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [onDelete, setOnDelete] = useState(false);
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { useFetch, useAppMutation } = useReactQuery();

  const { data: documentsData, isLoading: documentsDataLoading } = useFetch(
    ["get-documents", patientId], //patientId is mandatry for edit case
    () =>
      request({
        method: "get",
        url: `/get-documents?patient_id=${patientId}`,
      }),
    {
      enabled: !!patientId,
    }
  );

  //upload-attachments
  const { mutate: uploadMutation, isLoading: uploadMutationLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: "/patient/document-upload",
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("File Uploaded successfully", {
            type: "success",
          });
          queryClient.invalidateQueries(["get-documents"]);
          formik.resetForm();
          setSelectedFile(null);
        },
      }
    );

  const formik = useFormik({
    initialValues: {
      uploadFile: "",
    },
    validationSchema: DocumentsSchema,
    onSubmit: async (values) => {},
  });

  const handleFileChange = (file) => {
    setSelectedFile(file);
    formik.setFieldValue("uploadFile", file);
  };

  const handleFileUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file_url", file);
      formData.append("patient_id", patientId);
      uploadMutation(formData); // This triggers the file upload API call
    }
  };

  const openPdf = (url) => {
    window.open(url, "_blank", "height=600,width=800");
  };
  const AdminPaneltHeaderData = [
    { Header: "Title", accessor: "name" },
    { Header: "Type", accessor: "type" },
    { Header: "Date", accessor: "date" },
    {
      Header: "Patient Reports ",
      Cell: ({ row }) => {
        return (
          <Flex>
            <div
              className="preview_file"
              style={{
                cursor: "pointer",
                color: "#1C4588",
                textDecoration: "underline",
              }}
              onClick={() => openPdf(row?.original?.url)}
            >
              Preview
            </div>
          </Flex>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <TableActionButtons
            onDelete={() => {
              setSelectedDocument(row?.original);
              setOnDelete(true);
            }}
            showEdit={false}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (formik?.errors?.uploadFile) {
      showToast(formik?.errors?.uploadFile, {
        type: "error",
      });
    } else if (!!selectedFile) {
      handleFileUpload(selectedFile);
    }
  }, [formik?.errors?.uploadFile, selectedFile]);

  return (
    <>
      {documentsDataLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            <CustomModal
              setOpen={setOnDelete}
              open={onDelete}
              title={"Delete Insurance"}
              width="auto"
              maxWidth="tablet"
            >
              <DeleteDocuments
                patientId={patientId}
                setOnDelete={setOnDelete}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
              />
            </CustomModal>
            <Flex justifyContent="end" alignItems="center">
              {uploadMutationLoading ? (
                <Button margin="0" padding="0px 25px" isLoading={true} />
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#1c4588",
                    borderRadius: "6px",
                    boxSizing: "border-box",
                  }}
                >
                  <CustomImageUpload
                    my={1.4}
                    variant="body"
                    padding="0px 25px 0px 18px"
                    onFilesChange={handleFileChange}
                    label="Upload"
                    name="uploadFile"
                    textColor="white"
                    isShowIcon={false}
                  />
                </Box>
              )}
            </Flex>
          </Box>

          <GenericTable
            columns={AdminPaneltHeaderData}
            data={documentsData?.data}
          />
        </>
      )}
    </>
  );
};
export default memo(DocumentsData);
