import { Box, useTheme } from "@mui/material";
import { useFormik } from "formik";
import useToast from "hooks/useToast";
import Button from "jsx/Common/Button/Button";
import CustomTabs from "jsx/Common/CustomTabs/CustomTabs";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { DemographicsSchema } from "jsx/Screens/Ccm/Validations/AddPatient/DemographicsSchema";
import { useState } from "react";
import ContactForm from "./Components/ContactForm";
import DemographicsForm from "./Components/DemographicsForm";
import DiagnosisData from "./Components/DiagnosisData/DiagnosisData";
import DocumentsData from "./Components/DocumentsData/DocumentsData";
import EmployerForm from "./Components/EmployerForm";
import GuardianForm from "./Components/GuardianForm";
import ImmunizationRegistryFrom from "./Components/ImmunizationRegistryFrom";
import PharmacyFrom from "./Components/PharmacyFrom";
import Insurance from "./Insurance/Components/Insurance/Insurance";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import { useQueryClient } from "react-query";

export const AddPatient = ({ setOpen, selectedPatient, setIsEdit }) => {
  // state for tabs navigation
  const [tabValue, setTabValue] = useState(0);
  const [patientId, setPatientId] = useState(selectedPatient?.id ?? null);
  const theme = useTheme();
  const { showToast } = useToast();
  const { useAppMutation } = useReactQuery();
  const queryClient = useQueryClient();

  const { mutate: addNewPatientMutate, isLoading: addNewPatientLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: `/patients`,
          data,
        }),
      {
        onSuccess: (data) => {
          let message = patientId
            ? "Patient Updated Successfully"
            : "Patient Created Successfully";

          showToast(message, {
            type: "success",
          });
          if (!patientId) {
            setTabValue(6); // move on diagnosis tab
            setPatientId(data?.patient?.id);
          }
          queryClient.invalidateQueries(["ccm-patients"]);
          queryClient.invalidateQueries(["ccm-counts"]);
        },
      }
    );

  const formik = useFormik({
    initialValues: {
      patient_id: patientId ?? "",
      firstName: selectedPatient?.firstName ?? "",
      lastName: selectedPatient?.lastName ?? "",
      birthDate: selectedPatient?.birthDate ?? "",
      age: selectedPatient?.age ?? "",
      gender: selectedPatient?.gender ?? "",
      email: selectedPatient?.email ?? "",
      mobilePhone: selectedPatient?.mobilePhone ?? "",
      Address1: selectedPatient?.Address1 ?? "",
      city: selectedPatient?.city ?? "",
      zipcode: selectedPatient?.zipcode ?? "",
      state: selectedPatient?.state ?? "",

      type: "ccm", //type is mendatory for add Patient
      patient_img_id: selectedPatient?.patient_img_id ?? "",
      countryRegion: "US",
      imageUrl: selectedPatient?.attachments?.url ?? "",

      // Guardin form fields
      caregiverContact: selectedPatient?.caregiver_contact ?? "",
      guardianFullName: selectedPatient?.guardianFullName ?? "",
      caregiverRelationship: selectedPatient?.caregiver_relationship ?? "",

      // Contact form fields
      hpiContactMethod:
        selectedPatient?.patient_contact?.hpiContactMethod ?? "",
      referringProvider:
        selectedPatient?.patient_contact?.referringProvider ?? "",
      npi: selectedPatient?.patient_contact?.npi ?? "",
      emergencyContact:
        selectedPatient?.patient_contact?.emergencyContact ?? "",

      // Employer Form fields
      employerName: selectedPatient?.patient_employer?.employerName ?? "",
      employerContactNo:
        selectedPatient?.patient_employer?.employerContactNo ?? "",
      employerAddress1:
        selectedPatient?.patient_employer?.employerAddress1 ?? "",
      employerAddress2:
        selectedPatient?.patient_employer?.employerAddress2 ?? "",
      employerCity: selectedPatient?.patient_employer?.employerCity ?? "",
      employerZipCode: selectedPatient?.patient_employer?.employerZipCode ?? "",
      employerState: selectedPatient?.patient_employer?.employerState ?? "",

      // PharmacyFrom fields
      pharmacyName: selectedPatient?.patient_pharmacy?.pharmacyName ?? "",
      pharmacyPhone: selectedPatient?.patient_pharmacy?.pharmacyPhone ?? "",
      pharmacyAddress1:
        selectedPatient?.patient_pharmacy?.pharmacyAddress1 ?? "",

      // ImmunizationRegistry From fields
      immunizationProtection:
        selectedPatient?.patient_immunization_registry
          ?.immunizationProtection ?? "",
      publicityCode:
        selectedPatient?.patient_immunization_registry?.publicityCode ?? "",
    },
    validationSchema: DemographicsSchema,
    onSubmit: async (values) => {
      addNewPatientMutate(values);
    },
  });

  const handleCancel = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const tabsHeadind = [
    "Demographics",
    "Guardian",
    "Contact",
    "Employer",
    "Pharmacy",
    "Immunization",
    "Diagnosis",
    "Insurance",
    "Documents",
  ];

  const tabsData = [
    {
      component: <DemographicsForm formik={formik} />,
    },
    {
      component: <GuardianForm formik={formik} />,
    },
    {
      component: <ContactForm formik={formik} />,
    },
    {
      component: <EmployerForm formik={formik} />,
    },
    {
      component: <PharmacyFrom formik={formik} />,
    },
    {
      component: <ImmunizationRegistryFrom formik={formik} />,
    },
    {
      component: <DiagnosisData patientId={patientId} />,
    },
    {
      component: <Insurance patientId={patientId} />,
    },
    {
      component: <DocumentsData patientId={patientId} />,
    },
  ];
  return (
    <>
      <CustomTabs
        tabs={tabsHeadind}
        setTabValue={setTabValue}
        tabValue={tabValue}
        patientId={patientId}
      />
      <Box sx={{ px: 1, py: 2 }}>{tabsData?.[tabValue]?.component}</Box>

      {tabValue <= 5 ? (
        <Flex mt={2} gap={2}>
          <Button
            text={"save"}
            onClick={formik.submitForm}
            isLoading={addNewPatientLoading}
          />
          {tabValue < 5 ? (
            <Button
              onClick={() => {
                setTabValue(tabValue + 1);
              }}
              isLoading={addNewPatientLoading}
              text={"next"}
              sx={{
                px: 3,
              }}
            />
          ) : (
            ""
          )}

          <Button
            onClick={handleCancel}
            text={"cancel"}
            variant="contained"
            sx={{
              background: theme.palette.common.white,
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
              },
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};
