import { appRoutes } from "assets/static/appRoutes";
import { Navigate } from "react-router-dom";

const RedirectHandler = () => {
  const isAuth = !!localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const selected_company_name = localStorage.getItem("selected_company_name");
  const { companiesRedirect, homeRedirect, ccmHomeRedirect, loginRedirect } =
    appRoutes;

  const redirectTo = !isAuth
    ? loginRedirect
    : role === "admin"
    ? companiesRedirect
    : selected_company_name === "tcm"
    ? homeRedirect
    : selected_company_name === "ccm"
    ? ccmHomeRedirect
    : loginRedirect;

  return <Navigate to={redirectTo} replace />;
};

export default RedirectHandler;
