export const appRoutes = {
  homeRedirect: "/home",
  adminPanelRedirect: "/users",
  dashboardRedirect: "/dashboard",
  newpatientRedirect: "/new-patient",
  dischargePatientRedirect: "/patient/:id",
  dischargePatientReportsRedirect: "/patient/:id/reports",
  rolesRedirect: "/roles",
  privacyPolicyRedirect: "/privacy-policy",
  termsOfServiceRedirect: "/terms-conditions",
  selectReportsRedirect: "/reports",
  chooseCompany: "/chooseCompany",
  // Select Module for CCm or TCM
  selectModuleRedirect: "/chooseModule",

  // auth routes
  signUpRedirect: "/signup",
  loginRedirect: "/login",
  forgotPasswordRedirect: "/forgot-password",
  VerifyEmailRedirect: "/verify-email",
  SetNewPasswordRedirect: "/set-new-password",
  PasswordUpdatedRedirect: "/password-updated",

  // admin routes
  companiesRedirect: "/admin/company",
  usersRedirect: "/admin/users",
  patientsRedirect: "/admin/patients",
  deletedPatientsRedirect: "/admin/deleted-patients",
  multipleCompanyUsersRedirect: "/admin/multiple-company-users",
  questionsRedirect: "/admin/question",

  // ccm routes
  ccmHomeRedirect: "/ccm/home",
  ccmAddPatientRedirect: "/ccm/add-patient",
  initialCallsRedirect: "/ccm/initial-calls",
  monthlyCallsRedirect: "/ccm/monthly-calls",
  designCarePlanApprovalListRedirect: "/ccm/design-care-plan-approval-list",
  ccmDesignCarePlanRedirect: "/ccm/design-care-plan",
  designCarePlanApprovalRedirect: "/ccm/design-care-plan-approval",
  ccmMonthlyReviewWithInternalExpertRedirect:
    "/ccm/monthly-review-with-internal-expert",
  ccmMonthlyReviewWithProviderRedirect: "/ccm/monthly-review-with-provider",
  ccmReportsRedirect: "/ccm/reports",
};
