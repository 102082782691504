import { Autocomplete, TextField } from "@mui/material";
import request from "api/request";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const IcdsSearch = ({
  classes = "",
  icdData = {},
  icdDataLoading = false,
  helperText = "",
  formik,
  name,
  placeholder = "",
  errorMessage = "",
  selected = [],
  selectedSearchData = [],
}) => {
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState([]);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(selected ?? []);
  const [pageCount, setPageCount] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const showError = formik
    ? formik?.touched?.[name] && Boolean(formik?.errors?.[name])
    : false;
  const errorText = formik
    ? showError
      ? formik?.errors?.[name]
      : helperText
    : helperText;

  const { data, isLoading, fetchNextPage, hasNextPage } = useQuery(
    ["icd-code", inputValue, pageCount],
    () =>
      request({
        method: "get",
        url: `/icd-code?s=${inputValue}&page=${pageCount || 1}`,
      }),
    {
      onSuccess: (data) => {
        setIsScrolledToBottom(false);
        setTotalPageCount(data?.per_page);

        const filteredNewOptions = data?.data?.reduce((acc, item) => {
          const option = {
            value: item?.icd_code_id,
            label: `${item?.icd_code} - ${item?.icd_code_description}`,
          };

          const isAlreadySelected = selectedSearchData.some(
            (selected) => selected.icd_code_id === option.value
          );

          if (!isAlreadySelected) {
            acc.push(option);
          }

          return acc;
        }, []);

        setOptions((prevOptions) =>
          isScrolledToBottom && pageCount > 1
            ? [...prevOptions, ...filteredNewOptions]
            : filteredNewOptions
        );
      },
      // enabled: Boolean(inputValue),
    }
  );

  useEffect(() => {
    setPageCount(1);
  }, [inputValue]);

  const handleScroll = (event) => {
    const dropdownMenu = event.target;
    const lastLi = dropdownMenu.querySelector("li:last-child");

    if (lastLi) {
      const dropdownBottom = dropdownMenu.scrollTop + dropdownMenu.clientHeight;
      const lastLiBottom = lastLi.offsetTop + lastLi.clientHeight;

      if (dropdownBottom >= lastLiBottom) {
        setIsScrolledToBottom(true);
        if (hasNextPage) {
          fetchNextPage();
        }
      } else {
        setIsScrolledToBottom(false);
      }
    }
  };

  let dropdownMenu = document.querySelector(
    ".MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-listbox "
  );
  // Adjust the selector based on the actual class name

  if (dropdownMenu) {
    dropdownMenu?.addEventListener("scroll", handleScroll);
  }

  useEffect(() => {
    if (pageCount < totalPageCount) {
      setPageCount(pageCount + 1);
    }
  }, [isScrolledToBottom]);

  useEffect(() => {
    formik.setFieldValue(
      name,
      selectedOptions.map(({ value }) => value)
    );
  }, [selectedOptions]);

  useEffect(() => {
    return () => {
      dropdownMenu?.addEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Autocomplete
      className={classes}
      id={name}
      name={name}
      placeholder={placeholder}
      multiple
      limitTags={1}
      options={options ?? []}
      value={selectedOptions}
      getOptionLabel={(option) => option.label}
      loading={isLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        setSelectedOptions(newValue);
      }}
      renderInput={(params) => (
        <TextField
          error={showError}
          helperText={errorText}
          placeholder={placeholder}
          // error={fieldTouched && fieldError}
          // helperText={fieldTouched && fieldError && errorMessage}
          {...params}
        />
      )}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
    />
  );
};

export default IcdsSearch;
