import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";

const CustomTabs = ({
  tabs = [],
  centerProp = false,
  tabFont,
  setTabValue,
  tabValue = 0,
  patientId = null,
}) => {
  const useStyles = makeStyles((theme) => ({
    tabs: {
      textTransform: "capitalize",
      paddingLeft: "5px",
      paddingBottom: "5px",
      color: "#64748B",
      fontSize: tabFont ? "0.875rem" : "16px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.2px",
    },
  }));
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
        <Tabs
          centered={centerProp ? true : false}
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            sx: {
              height: "3px",
              borderRadius: "100px",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              wrapped
              label={tab}
              key={index}
              className={classes.tabs}
              disabled={
                (patientId === null ||
                  patientId === "" ||
                  patientId === undefined) &&
                index > 5
                  ? true
                  : false
              }
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};
export default memo(CustomTabs);
