import { Box, Grid } from "@mui/material";
import Flex from "../Layouts/Flex/Flex";
import Text from "../Typography/Text";
import moment from "moment";
import { isValueTruthy } from "utils/isValueTruthy";
const PatientDetail = ({
  selectedRecord = {},
  patientRecord = {},
  isCcm = false,
}) => {
  return (
    <>
      {isCcm ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
        >
          <Flex justifyContent="space-between">
            <Box>
              <Text variant="h3" color="gray" marginTop="20px">
                Patient Details
              </Text>
            </Box>
            <Box
              sx={{
                width: "max-content",
                padding: "3px",
              }}
            >
              <Flex justifyContent="flex-start">
                <Text
                  variant="h4"
                  color="blue"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  &nbsp;&nbsp;&nbsp;UUID: &nbsp;
                </Text>
                <Text
                  variant="body2"
                  color="grey"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  {`${patientRecord?.uuid ?? ""}`}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Patient Name: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${patientRecord.firstName ?? ""} ${
                  patientRecord.initialName ?? ""
                } ${patientRecord.lastName ?? ""}`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Date Of Birth: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {moment(`${patientRecord?.birthDate ?? ""}`).format(
                  "MM-DD-YYYY"
                )}{" "}
                &nbsp;
                {`(${patientRecord?.age ?? ""} yrs)`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Gender: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`
                  ${patientRecord?.gender ?? ""}`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Address: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${patientRecord?.Address1 ?? ""}`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Email: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {patientRecord?.email ?? ""}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Phone #: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {patientRecord?.mobilePhone ?? ""}
              </Text>
            </Flex>
          </Grid>

          {!isValueTruthy(patientRecord?.caregiver_relationship) ? (
            ""
          ) : (
            <>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Guardian Relation: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {(patientRecord?.caregiver_relationship ??
                      selectedRecord?.caregiver_relationship) ||
                      ""}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Guardian Name: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {patientRecord?.guardianFullName ?? ""}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Guardian Contact: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {patientRecord?.caregiver_contact ?? ""}
                  </Text>
                </Flex>
              </Grid>
            </>
          )}
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Insurance: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                Insurance
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Insurance #: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                Insurance #
              </Text>
            </Flex>
          </Grid>
        </Grid>
      ) : (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="space-between">
            <Box>
              <Text variant="h3" color="gray" marginTop="20px">
                Patient Details
              </Text>
            </Box>
            <Box
              sx={{
                width: "max-content",
                padding: "3px",
              }}
            >
              <Flex justifyContent="flex-start">
                <Text
                  variant="h4"
                  color="blue"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  MRN #: &nbsp;
                </Text>
                <Text
                  variant="body2"
                  color="grey"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  {selectedRecord?.mrn ??
                    selectedRecord?.discharge_patient?.mrn ??
                    "---"}
                </Text>
                <Text
                  variant="h4"
                  color="blue"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  &nbsp;&nbsp;&nbsp;UUID: &nbsp;
                </Text>
                <Text
                  variant="body2"
                  color="grey"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  {`${
                    patientRecord?.patient?.uuid ??
                    selectedRecord?.patient?.uuid
                  }`}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Patient Name: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientRecord?.patient?.firstName ??
                  selectedRecord?.patient?.firstName
                } ${
                  patientRecord?.patient?.initialName ??
                  selectedRecord?.patient?.initialName
                } ${
                  patientRecord?.patient?.lastName ??
                  selectedRecord?.patient?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Date Of Birth: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {moment(
                  `${
                    patientRecord?.patient?.birthDate ??
                    selectedRecord?.patient?.birthDate
                  }`
                ).format("MM-DD-YYYY")}{" "}
                &nbsp;
                {`(${
                  patientRecord?.patient?.age ?? selectedRecord?.patient?.age
                } yrs)`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Gender: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`
                  ${
                    patientRecord?.patient?.gender ??
                    selectedRecord?.patient?.gender
                  }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Address: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientRecord?.patient?.Address1 ??
                  selectedRecord?.patient?.Address1
                }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Email: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {(patientRecord?.patient?.email ??
                  selectedRecord?.patient?.email) ||
                  ""}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Phone #: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientRecord?.patient?.mobilePhone ??
                  selectedRecord?.patient?.mobilePhone
                }`}
              </Text>
            </Flex>
          </Grid>
          {(patientRecord?.patient?.otherPhone ||
            selectedRecord?.patient?.otherPhone) && (
            <Grid item mobile={12} tablet={6} laptop={4}>
              <Flex alignItems="flex-start" justifyContent="flex-start">
                <Text variant="h4" color="blue">
                  Secondary Phone #: &nbsp;
                </Text>

                <Text variant="body2" color="grey">
                  {`${
                    patientRecord?.patient?.otherPhone ??
                    selectedRecord?.patient?.otherPhone
                  }`}
                </Text>
              </Flex>
            </Grid>
          )}

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Current Insurance: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  selectedRecord?.insurance?.insuranceName ??
                  selectedRecord?.discharge_patient?.insurance?.insuranceName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Insurance ID: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  selectedRecord?.insuranceNumber ??
                  selectedRecord?.discharge_patient?.insuranceNumber
                }`}
              </Text>
            </Flex>
          </Grid>

          {!isValueTruthy(
            patientRecord?.patient?.caregiver_relationship ||
              selectedRecord?.patient?.caregiver_relationship
          ) ? (
            ""
          ) : (
            <>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Relation: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {(patientRecord?.patient?.caregiver_relationship ??
                      selectedRecord?.patient?.caregiver_relationship) ||
                      ""}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Name: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {(patientRecord?.patient?.caregiver_name ??
                      selectedRecord?.patient?.caregiver_name) ||
                      ""}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Contact: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {(patientRecord?.patient?.caregiver_contact ??
                      selectedRecord?.patient?.caregiver_contact) ||
                      ""}
                  </Text>
                </Flex>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default PatientDetail;
