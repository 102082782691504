import { Navigate } from "react-router-dom";
import { appRoutes } from "assets/static/appRoutes";
import Login from "jsx/Screens/Auth/Login/Login";

const LoginOrRedirect = () => {
  const isAuth = !!localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const selected_company_name = localStorage.getItem("selected_company_name");
  const { companiesRedirect, homeRedirect, ccmHomeRedirect } = appRoutes;
  if (!isAuth) {
    return <Login />;
  } else {
    const redirectTo =
      selected_company_name === "tcm"
        ? homeRedirect
        : selected_company_name === "ccm"
        ? ccmHomeRedirect
        : role === "admin"
        ? companiesRedirect
        : "";
    return <Navigate to={redirectTo} replace />;
  }
};
export default LoginOrRedirect;
