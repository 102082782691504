import * as yup from "yup";

const DocumentsSchema = yup.object().shape({
  uploadFile: yup
    .mixed()
    .required("File is required")
    .test(
      "fileFormat",
      "Only PDF, JPG, JPEG, or TXT files are allowed",
      (value) => {
        if (value) {
          const supportedFormats = ["pdf", "jpg", "jpeg", "txt"];
          return supportedFormats.includes(value?.name?.split(".").pop());
        }
        return true;
      }
    )
    .test("fileSize", "File size must not be more than 10MB", (value) => {
      if (value) {
        return value.size <= 10485760; // 10 MB in bytes
      }
      return true;
    }),
});

export default DocumentsSchema;
