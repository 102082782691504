import { Box } from "@mui/material";
import { appRoutes } from "assets/static/appRoutes";
import useFilteredPermissions from "hooks/useFilteredPermissions";
import CustomModal from "jsx/Common/Modal/CustomModal";
import ChooseCompany from "jsx/Screens/Auth/Login/ChooseCompany";
import ForgotPasswordScreen from "jsx/Screens/Auth/Login/ForgotPasswordScreens/ForgotPasswordScreen";
import PasswordUpdated from "jsx/Screens/Auth/Login/ForgotPasswordScreens/PasswordUpdated";
import SetNewPassword from "jsx/Screens/Auth/Login/ForgotPasswordScreens/SetNewPassword";
import VerifyEmail from "jsx/Screens/Auth/Login/ForgotPasswordScreens/VerifyEmail";
import PrivacyPolicy from "jsx/Screens/Auth/Login/PrivacyPolicyFile/PrivacyPolicy";
import TermsOfServices from "jsx/Screens/Auth/Login/PrivacyPolicyFile/TermsOfServices";
import SelectModule from "jsx/Screens/Auth/Login/SelectModule/SelectModule";
import { AddPatient } from "jsx/Screens/Ccm/AddPatient";
import DesignCarePlan from "jsx/Screens/Ccm/DesignCarePlan/DesignCarePlan";
import DesignCarePlanApproval from "jsx/Screens/Ccm/DesignCarePlanApproval/DesignCarePlanApproval";
import DesignCarePlanApprovalList from "jsx/Screens/Ccm/DesignCarePlanApprovalList/DesignCarePlanApprovalList";
import MonthlyCall from "jsx/Screens/Ccm/MonthlyCall/MonthlyCall";
import DischargePatient from "jsx/Screens/Home/NewDischargePatient/DischargePatient";
import DischargePatientReport from "jsx/Screens/Home/NewDischargePatient/DischargePatientReport";
import ReportList from "jsx/Screens/Home/SelectReports/ReportList";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { QueryClient } from "react-query";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import RolesManagement from "../jsx/Screens/Home/RolesManagment/RolesManagement";
import LoginOrRedirect from "../routing/LoginOrRedirect";
import RedirectHandler from "./RedirectHandler";

const Companies = React.lazy(() => import("jsx/Screens/AdminPanel/Companies"));
const Users = React.lazy(() => import("jsx/Screens/AdminPanel/Users"));
const Patients = React.lazy(() => import("jsx/Screens/AdminPanel/Patients"));
const DeletedPatients = React.lazy(() =>
  import("jsx/Screens/AdminPanel/DeletedPatients")
);
const MultipleCompanyUsers = React.lazy(() =>
  import("jsx/Screens/AdminPanel/MultipleCompanyUsers")
);
const Questions = React.lazy(() => import("jsx/Screens/AdminPanel/Questions"));

const Login = React.lazy(() => import("jsx/Screens/Auth/Login/Login"));
const PrivateRoute = React.lazy(() => import("./PrivateRoutes"));
const Dashboard = React.lazy(() => import("jsx/Screens/Dashboard"));
const AdminPanel = React.lazy(() => import("jsx/Screens/AdminPanel"));
const NewPatient = React.lazy(() => import("jsx/Screens/NewPatient"));
const Reports = React.lazy(() => import("jsx/Screens/Reports"));
const Home = React.lazy(() => import("jsx/Screens/Home"));

// ccm routes
const CCMHome = React.lazy(() => import("jsx/Screens/Ccm/Home"));

const MonthlyReviewWithInternalExpert = React.lazy(() =>
  import(
    "jsx/Screens/Ccm/MonthlyReviewWithInternalExpert/MonthlyReviewWithInternalExpert"
  )
);
const MonthlyReviewWithProvider = React.lazy(() =>
  import("jsx/Screens/Ccm/MonthlyReviewWithProvider/MonthlyReviewWithProvider")
);

const Router = ({ theme }) => {
  const isAuth = !!localStorage.getItem("token");
  const companies = JSON.parse(localStorage.getItem("companies"));

  const selected_company_name = localStorage.getItem("selected_company_name");

  const queryClient = new QueryClient();
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const {
    homeRedirect,
    dashboardRedirect,
    adminPanelRedirect,
    newpatientRedirect,
    selectReportsRedirect,
    rolesRedirect,
    chooseCompany,
    loginRedirect,
    privacyPolicyRedirect,
    termsOfServiceRedirect,
    forgotPasswordRedirect,
    VerifyEmailRedirect,
    SetNewPasswordRedirect,
    PasswordUpdatedRedirect,
    dischargePatientReportsRedirect,
    dischargePatientRedirect,
    companiesRedirect,
    usersRedirect,
    patientsRedirect,
    deletedPatientsRedirect,
    multipleCompanyUsersRedirect,
    questionsRedirect,
    ccmAddPatientRedirect,
    ccmDashboardRedirect,
    initialCallsRedirect,
    designCarePlanApprovalRedirect,
    designCarePlanRedirect,
    designCarePlanApprovalListRedirect,
    monthlyCallsRedirect,
    selectModuleRedirect,
    ccmHomeRedirect,
    ccmPatientsRedirect,
    ccmDesignCarePlanRedirect,
    ccmMonthlyReviewWithInternalExpertRedirect,
    ccmMonthlyReviewWithProviderRedirect,
    ccmReportsRedirect,
  } = appRoutes;

  const onIdle = () => {
    localStorage.getItem("token") && setIsAlert(true);
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onIdle,

    // timeout: 3000,
    timeout: 1000 * 60 * 20,

    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 5,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const PermissionPrivateRoute = ({ element, permission }) => {
    const { hasPermission } = useFilteredPermissions();
    return hasPermission(permission) ? (
      element
    ) : (
      <Navigate to={homeRedirect} replace />
    );
  };

  // Define a custom route component for admin routes
  const AdminPrivateRoute = () => {
    const auth = role === "admin";
    return auth ? <Outlet /> : <Navigate to={homeRedirect} />;
  };

  // Define a custom route component for ccm routes
  const CcmPrivateRoute = () => {
    const authCcm = selected_company_name === "ccm";
    return authCcm ? <Outlet /> : <Navigate to={loginRedirect} />;
  };

  // Define a custom route component for tcm routes
  const TcmPrivateRoute = () => {
    const authTcm = selected_company_name === "tcm";
    return authTcm ? <Outlet /> : <Navigate to={loginRedirect} />;
  };

  useEffect(() => {
    setTimeout(function () {
      if (isAlert) {
        localStorage.clear();
        queryClient.clear();
        navigate("/login");
      }
    }, 3000);
  }, [isAlert, navigate, queryClient]);

  return (
    <>
      <CustomModal setOpen={setIsAlert} open={isAlert} title={"Alert"}>
        <Box sx={{ padding: "10px" }}>
          You were automatically logged out for security reasons since you had
          no activity in 20 minutes.
        </Box>
      </CustomModal>

      <Routes>
        <Route path="/login" element={<LoginOrRedirect />} />

        {!isAuth && <Route path="/" element={<Login />} />}

        <Route path={chooseCompany} element={<ChooseCompany />} />

        <Route path={selectModuleRedirect} element={<SelectModule />} />
        <Route path={privacyPolicyRedirect} element={<PrivacyPolicy />} />
        <Route path={termsOfServiceRedirect} element={<TermsOfServices />} />
        <Route
          path={forgotPasswordRedirect}
          element={<ForgotPasswordScreen />}
        />
        <Route path={VerifyEmailRedirect} element={<VerifyEmail />} />
        <Route path={SetNewPasswordRedirect} element={<SetNewPassword />} />
        <Route path={PasswordUpdatedRedirect} element={<PasswordUpdated />} />

        <Route element={<PrivateRoute theme={theme} />}>
          {/* tcm routes */}
          <Route element={<TcmPrivateRoute />}>
            <Route
              path={adminPanelRedirect}
              element={
                <PermissionPrivateRoute
                  element={<AdminPanel />}
                  permission="user_view"
                />
              }
            />
            <Route
              path={dashboardRedirect}
              element={
                <PermissionPrivateRoute
                  element={<Dashboard />}
                  permission="dashboard_view"
                />
              }
            />
            <Route
              path={newpatientRedirect}
              element={
                <PermissionPrivateRoute
                  element={<NewPatient />}
                  permission="patient_view_create_edit"
                />
              }
            />
            <Route
              path={dischargePatientReportsRedirect}
              element={<DischargePatientReport />}
            />
            <Route
              path={homeRedirect || "/"}
              element={
                role === "admin" ? (
                  <Navigate to={companiesRedirect} replace />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path={dischargePatientRedirect}
              element={<DischargePatient />}
            />
            <Route
              path={rolesRedirect}
              element={
                <PermissionPrivateRoute
                  element={<RolesManagement />}
                  permission="rolesManagement_view"
                />
              }
            />
            <Route
              path={selectReportsRedirect}
              element={
                <PermissionPrivateRoute
                  element={<ReportList />}
                  permission="report_view"
                />
              }
            />
          </Route>

          {/* CCM routes */}
          <Route element={<CcmPrivateRoute />}>
            <Route path={ccmHomeRedirect} element={<CCMHome />} />
            <Route path={ccmReportsRedirect} element={<h1>Reports</h1>} />
            {/* Comment fot later */}
            <Route path={ccmAddPatientRedirect} element={<AddPatient />} />
            <Route
              path={designCarePlanApprovalListRedirect}
              element={<DesignCarePlanApprovalList />}
            />
            <Route path={monthlyCallsRedirect} element={<MonthlyCall />} />
            <Route path={designCarePlanRedirect} element={<DesignCarePlan />} />
            <Route
              path={designCarePlanApprovalRedirect}
              element={<DesignCarePlanApproval />}
            />
            <Route
              path={ccmDesignCarePlanRedirect}
              element={<DesignCarePlan />}
            />
            <Route
              path={ccmMonthlyReviewWithInternalExpertRedirect}
              element={<MonthlyReviewWithInternalExpert />}
            />
            <Route
              path={ccmMonthlyReviewWithProviderRedirect}
              element={<MonthlyReviewWithProvider />}
            />
          </Route>

          {/* admin routes */}
          <Route element={<AdminPrivateRoute />}>
            <Route path={companiesRedirect} element={<Companies />} />
            <Route path={usersRedirect} element={<Users />} />
            <Route path={patientsRedirect} element={<Patients />} />
            <Route
              path={deletedPatientsRedirect}
              element={<DeletedPatients />}
            />
            <Route
              path={multipleCompanyUsersRedirect}
              element={<MultipleCompanyUsers />}
            />
            <Route path={questionsRedirect} element={<Questions />} />
          </Route>
        </Route>
        <Route path="*" element={<RedirectHandler />} />
      </Routes>
    </>
  );
};

export default Router;
